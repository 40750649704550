import { getDataByEnv } from '@/core/utils'

const customDataByEnv = {
  namespace: {
    production: '0ff8c41e-ebcf-4574-95bc-90e800c5948c',
    development: 'c5a84076-af99-4a3d-975e-1a919eb49829',
  },
}

export default {
  name: {
    nb: 'Kokk- og servitørfag vg2',
    nn: 'Kokk- og servitørfag vg2',
  },
  slug: 'ksfag',
  namespace: getDataByEnv('namespace', customDataByEnv),
  assets: {
    logo: '/themes/ksfag/images/logo.svg',
    favicon: '/themes/automatiseringsteknikk/favicon.ico',
  },
  styles: [
    '/themes/ksfag/css/ksfag.css',
    '/common/icons/icons.css',
    'https://cdn.jsdelivr.net/npm/katex@0.13.2/dist/katex.min.css',
  ],
  welcomeMessage: {
    nb: '<h1>Velkommen til Kokk- og servitørfag vg2</h1><p>Læremiddelet er utgitt med støtte fra Utdanningsdirektoratet.</p>',
    nn: '<h1>Velkommen til Kokk- og servitørfag vg2</h1><p>Læremiddelet er gitt ut med støtte frå Utdanningsdirektoratet.</p>',
  },
  langs: [
    {
      code: 'nb',
      label: 'Bokmål',
    },
    {
      code: 'nn',
      label: 'Nynorsk',
    },
  ],
  mainNav: {
    nb: [
      {
        title: 'Fagbegreper',
        to: '/concepts/fagbegreper',
      },
      {
        title: 'Praktiske oppgaver',
        to: '/articles/yrkesoppgaver',
      },
      {
        title: 'Fagartikler',
        to: '/articles/fagartikler',
      },
      {
        title: 'Oppskrifter',
        to: '/articles/oppskrifter',
      },
      {
        title: 'Multimedia',
        to: '/media/multimedia',
      },
      {
        title: 'MyFoodOffice',
        href: 'https://myfoodoffice.fagbokforlaget.no',
      },
      {
        title: 'Rettelser',
        to: '/files/rettelser',
      },
      {
        title: 'Lærerens verktøykasse',
        to: '/files/laererens-verktoykasse',
        accessLevel: 'TEACHER'
      },
    ],
    nn: [
      {
        title: 'Fagomgrep',
        to: '/concepts/fagbegreper',
      },
      {
        title: 'Praktiske oppgåver',
        to: '/articles/yrkesoppgaver',
      },
      {
        title: 'Fagartiklar',
        to: '/articles/fagartikler',
      },
      {
        title: 'Oppskrifter',
        to: '/articles/oppskrifter',
      },
      {
        title: 'Multimedium',
        to: '/media/multimedia',
      },
      {
        title: 'MyFoodOffice',
        href: 'https://myfoodoffice.fagbokforlaget.no',
      },
      {
        title: 'Rettingar',
        to: '/files/rettelser',
      },
      {
        title: 'Lærarens verktøykasse',
        to: '/files/laererens-verktoykasse',
        accessLevel: 'TEACHER'
      }
    ],
  },
  docToolUrl: 'https://ksdok.fagbokforlaget.no',
}
